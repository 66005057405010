@footer-height : 50px;
footer {
  display: block;
  .transition(left, 0.5s);
  .font(14);
  color: @brand-third;
  background-color: @font-ultralight-color;
  text-align: left;
  font-family:@font-family-base;
  height: @footer-height; 
  line-height: @footer-height;
  vertical-align: middle;
  border-top: 1px inset #b2b2b2;
  margin-top: 90px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    border-top: 5px solid @font-ultralight-color;
    position: relative;
    top: -6px;
  }

  @media @mobile {
    position: relative;
    width: 100%;
    height: @triple-padding;
    line-height: @triple-padding;
    left: 0;
    right: 0;
  }
  .spacer {
    margin: 0 5px;
    display: inline-block;
  }

  a{
    color: @font-dark-color;
    text-decoration: underline;
    display: inline-block;
    margin: 0 5px;
    &:hover,
    &:focus {
      color: @nav-link-hover-color;

    }
  }
  span{
    margin: 0 5px;
    float: right;
    color: @brand-third;
    display: inline-block;
  }
}