.block-not-cropped{
  display: flex;
  flex-wrap: wrap;
  padding-top: @simple-padding;
  //flex-flow: row wrap;
  //justify-content: space-between;
  &:after {
    content: '';
    flex-grow: 999999999;
    flex: auto;
  }
  &:nth-last-child {
    margin-right: auto;
  }
  & li {      
    position: relative;
    list-style: none;    
    z-index: 0;
    @item-gutter: 5px;
    margin-left: @item-gutter;
    margin-bottom: @item-gutter;
    overflow: hidden;
    .border-radius();
    background: -webkit-linear-gradient(-45deg, #000 0%,#fff 100%);
    background: linear-gradient(-45deg, #000 0%,#fff 100%);
    min-width: 15%;
    min-height: 200px;
    max-height: 350px;
    flex: 1 0 18%;
    @media @tablet {
      min-width: 30%;
      flex: 1 0 30%;
    }
    @media @mobile {
      min-width: 20%;
      flex: 1 0 50%;
    }
    &.block-paginate {
      @media @mobile {
        float: none;
        margin: 0 auto;
        padding: 0;
        clear: both;
      }
    }
    & .item-paginate {
      margin-top: 1px;
      margin-left: -1px;
      display: table;
      padding: @double-padding; 
      background-color: transparent !important;  
      outline: 1px solid @blocks-paginate-outline;
      @media @mobile {
        height: 40px !important;
        padding: 5px 20px;
        margin: 0 auto;
        display: inline-block;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
        border: solid 1px #686868;
      }
      & a {
        display: table-cell;
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        .font(22);
        color: @brand-second;
        @media @mobile {
          text-align: center;
          display: block;
          color: #4c4c4c;
          font-size: 14px;
          font-weight: bold;
        }
        &:hover{
          text-decoration: none; 
        }
      }
    }
    
    & .item{     
      outline: 1px solid @blocks-outline; 
      box-sizing: border-box;
      border: 3px solid transparent;
      height: 100%;
      overflow: hidden;
      background: @font-ultralight-color;
      position: relative;
      & .draggable {
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        padding: 5px;
        border-radius: 15px;
        &:before {
          content:'';
          background-image:url('../img/drag.png');
          width:20px;
          height:20px;
          background-size: contain;
        }
      }

      & .image {
        .border-radius();
        display: table;
        height: 100%;
        width: 100%;
        & a{    
          position: relative;
          cursor: pointer;
          width: inherit;
          text-align: center;
          vertical-align: middle;
          max-height: inherit;
          display: table;
          height: 100%;
          .border-radius();
          & .icon-only{
            width: 100%;
            height: 100%;
            background-color: @soft-border-color;
            line-height: inherit;
            padding: 0;
            vertical-align: middle;
            display: table-cell;
            padding-bottom: 60px;
            & span{
              .file-icon-large();
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        & .thumbnail-wrapper {
          height: 100%;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          & img {
            vertical-align: bottom;
            position: relative;
            opacity: 1;
            -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
            transition: opacity 0.35s, transform 0.35s;
            -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            z-index: 20;
          }
          & .background-thumbnail {
            -webkit-transition: -webkit-transform 0.35s;
            transition: transform 0.35s;
            -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
          }
        }
        &.portrait {
          & img {
            min-width: 100%;
            height: 200px;
            object-fit: cover;
            margin: -10px 0 0 -10px;
            max-width: none;
            width: -webkit-calc(100% + 10px);
            width: calc(100% + 10px);
          }
        }
        &.landscape {
          & img {
            min-width: 100%;
            height: 200px;
            object-fit: cover;
            margin: -10px 0 0 -10px;
            max-width: none;
            width: -webkit-calc(100% + 10px);
            width: calc(100% + 10px);
          }
        }
      }


      & .caption {
        padding: 10px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-transform: none;
        font-size: 90%;
        z-index: 30;
        height: 60px;
        background-color: #11111188;
        .transition(background-color, 0.25s);
        & .file-icon {
          position: absolute;
          z-index: 2;
          bottom: 10px;
          right: 15px;
        }
        & > a {
          display: inline-block;
          & .media-title{
            display: inline-block;
            vertical-align: top;
            width: 100%;
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            text-align: left;
            .font(15);
            font-weight: bold;
            color: @font-ultralight-color;
            word-break: break-word;
          }
        }
        & .record-counter {
          position: absolute;
          right: 10px;
          bottom: 10px;
          .font(20);
          color: @font-ultralight-color;
          text-shadow: 2px 1px 0 #444;
          font-weight: bold;
        }
        & .extra-fields{
          display: block;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: @font-ultralight-color;
          opacity: 0;
          -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
          transition: opacity 0.35s, transform 0.35s;
          -webkit-transform: translate3d(0,50px,0);
          transform: translate3d(0,50px,0);
          & p{
            .font(12);
            margin: 0;
            color: @font-ultralight-color;
          }
        }
      }
      & .actions {
        left: @item-gutter;
        right: -2px;
        width: auto;
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 30;
        & a{
          width: 100%;
          height: 100%;
          display: block;
          margin-left: - @item-gutter;
        }
        & .bnt-wrapper{
          width: 80px;
          float: right;
          height: 50px;
          line-height: 40px;
          position: relative;
          top: 5px;
          z-index: 20;
          display: none;
          & button {
            padding: 0;
            margin: 0 7px;
            color: @font-ultralight-color;
            &:hover {
              color: @brand-primary;
            }
          }
        }
      }
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        z-index: 11;
        padding: 10px;
      }    
    }  
  }
}
 
// ############### ANIMATIONS & BEHAVIOURS ###############  //

figure.effect-lexi p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 1.5em 0;
  width: 140px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(5px,5px,0);
  transform: translate3d(5px,5px,0);
}


.block-not-cropped{
  li{
    #hover () {
      .hover() {
        & .item-paginate{
          & a{
            color: @brand-primary;
          }
        }

        & .item{
          & .draggable {
            display: inline-block;
          }

          & a {
            color: @brand-primary;
            cursor: pointer;
          }
          & .image{
            & .thumbnail-wrapper{
              & img {
                opacity: 1;
                -webkit-transform: translate3d(5px,5px,0);
                transform: translate3d(5px,5px,0);
              }
              & .background-thumbnail {
                -webkit-transform: translate3d(5px,5px,0);
                transform: translate3d(5px,5px,0);
              }
            }
          }

          & .caption{
            height: auto;
            background-color: @brand-primary-alpha;
            & .media-title{

              -webkit-transform: translate3d(5px,5px,0);
              transform: translate3d(5px,5px,0);
            }
            & .extra-fields {
              opacity: 1;
              -webkit-transform: translate3d(5px,5px,0);
              transform: translate3d(5px,5px,0);
            }
          }

          & .actions{
            display: inline-block;
            & .bnt-wrapper {
              display: block;
              &:before {
                opacity: 1;
                -webkit-transform: scale3d(1,1,1);
                transform: scale3d(1,1,1);
              }
            }
            &:before {
              content: '';
              -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
              transition: opacity 0.35s, transform 0.35s;
              position: absolute;
              right: -8px;
              top: -20px;
              width: 90px;
              height: 90px;
              border: 2px solid #fff;
              background: #00000055;
              border-radius: 50%;
              -webkit-transform: scale3d(1.5,1.5,1);
              transform: scale3d(1.5,1.5,1);
              -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
            }
          }
        }

        .selector-container{
          visibility: visible;
          z-index: 70;
          & input {
            .icon-checkbox_off();
            z-index: 999;
          }
        }
      }
    }

    &:hover, &.selected, &.currentHighlight {
      #hover > .hover();
    }
    @media @mobile, @tablet {
      #hover > .hover();
    }

    ///END HOVER
    &.selected {
      & .item {
        border: 3px solid @brand-primary;
        & .actions  {
          & .bnt-wrapper {
            display: none;
          }
          &:before {
            width: 0;
            height: 0;
          }
        }
      }
    }


  }
}
