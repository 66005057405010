@nav-height: 80px;
@logo-width: 260px;
.main-nav{}

.logo-container{
  .container(@logo-width);
  height: @header-height;
  line-height: @header-height;
  text-align: center;
  display: inline-block;
  position: relative;
  z-index: 1000;
  float: left;
  margin-left: @simple-padding;
  & a {     
    display: block;
    line-height: inherit;
    vertical-align: center;
    margin: auto;
    height: 100%;
    .bg-contain(@white-logo-png,center,center);
  }
}
html[lang=en] {
  .logo-container{
    & a {
      .bg-contain(@white-logo-en-png,center,center);
    }
  }
}

.navbar{
  display: inline-block;
  width: 100%;
  margin: 0;
  font-family:@font-family-base;
  color: @font-base-color;
  .font-size(13);
  vertical-align: top;
  border-bottom: 1px solid @brand-second;
  padding: 0 @simple-padding;
  @media @mobile, @tablet {
    border-bottom: 0;
  }

  & ul.navbar-nav {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: @nav-height;
    vertical-align: middle;

    & li {
      display: inline-block;
      width: 100%;
      float: left;
      color: @font-dark-color;
      margin: 0;
      padding: 0;
      font-weight: 300;
      height: @nav-height - 20;
      line-height: @nav-height - 20;
      vertical-align: top;
      border-bottom: 4px inset transparent;

      &.active {
        border-bottom: 4px inset @brand-primary;
        & a {
          color: @brand-primary;
        }
      }
      &:hover {
        cursor: pointer;
        color: @brand-primary;
        border-bottom: 4px inset @brand-primary;
      }

      > a {
        position: relative;
        display: block;
        color: inherit;
        .font-size(17);
        font-weight: 500;
        line-height: inherit;
        padding: 0;
        border-bottom: 4px inset transparent;
        text-transform: capitalize;
        &:hover,
        &:focus {
          text-decoration: none;
          color: @nav-link-hover-color;
        }
      }
    }
  }

  .filters-wrapper.collapse {
    display: block;
  }

  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: -15px;
  }
  /////////////////////////////////////////////////////////////////////////////////////// CART //////
}