

@toolbar-height: 70px;

/////////////////////BASE MODAL OVERLAY -- LAYOUT  ////////////////////


.overlay {
  .rgba(246, 246, 246, .95);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scroll: hide;

  height: 100%;
  top: 0;

  /*  .media-infos {
      display: inline-block;
      position: absolute;
      left: 2rem;
      top: 1rem;
      border-radius: 4px;
      background-color: rgba(47, 150, 181, 0.5);
      padding: 5px;
      font-size: 1.6rem;
      & p {
        line-height: 1.5rem;
        color: @font-light-color;
        margin: 5px 0;
      }
    }*/

  .overlay-content {
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    position: absolute;
    display: block;
    top: 0;
  }

  .media-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    & h3 {
      .font(24);
      font-weight: bold;
      border-bottom: 1px solid @brand-primary;
      padding-bottom: 15px;
      margin-top: 1.5rem;
      word-wrap: break-word;
    }
  }
}

.media-slider-container {
  padding-bottom: 180px!important;
}

[data-no-slider] {
  & .media-slider-container {
    padding-bottom: 50px!important;
  }

  & .slide-list {
    .hide();
  }

  & .overlay-slide-buttons{
    .hide();
  }
}

.mediaContainer {
  float:left;
  width: 94%;
  height: 100%;
  padding: @double-padding;
  padding-bottom: @double-padding + @double-padding ;
  .transition(padding, 0.5s);

  @media @mobile {
    padding: @simple-padding;
    padding-right: @double-padding;
  }
  & .preview{
    position: relative;
    width: 100%;
    background-color: transparent;
    display:inline-block;
    height:100%;
    overflow:hidden;
    padding-bottom: 40px;
    & [data-viewer="image"]{
      &:before {
        display:inline-block;
        content: '';
        height:100%;
        width:0;
        vertical-align:middle;
      }
    }
    & .preview-wrapper[data-viewer="audio"]{
      & img {
        width: 20%;
        min-height: inherit;
        display: block;
        margin: 20px auto;
      }
    }

    & .preview-wrapper[data-viewer="image"] {
      & img {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        cursor: grab;
        cursor: -webkit-grab;
      }
    }
    & .preview-wrapper{
      display: block;
      width: 100%;
      height:100%;
      text-align: center;
      background-color: transparent;
      position: relative;
      overflow: scroll;
      overflow-x: hidden;
      right: -17px;
      @media @mobile {
        right: 0;
      }
      & .video-js {
        margin: 0 auto;
        display: table-cell;
        vertical-align: middle;
        position: initial;
        background: none;
      }

      & img{
        max-width: 100%;
        display: inline-block;
        max-height: 100%;
        vertical-align: middle;
        width: auto;
        height: auto;
      }
      & .icon-only{
        display: inline-block;
        max-height: 100%;
        vertical-align: middle;
        width: auto;
        & .file-icon{
          display: inline-block;
          vertical-align: middle;
          .file-icon-xlarge();
        }
      }
      & canvas{

        overflow: scroll;
        display:block;
        margin:0 auto;
        margin-bottom:3%;
        &:nth-last-of-type(1){
          margin-bottom:0;
        }
      }
    }
  }
  .portrait{
    & img{
      height: initial;
    }
  }
}


.slide-list {
  display: block;
  height: 130px;
  position: absolute;
  bottom: 0;
  width: 94%;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  background: @font-ultralight-color;
  &[data-toggle-state=visible] {
    width: 65%;
    @media @tablet {
      width: 55%;

    }
    .mq-max(1450px,
    {
      .overlay-slider{ width: 65%;}
    }
    );
    .mq-max(1200px,
    {
      .overlay-slider{ width: 60%;}
    }
    );
    .mq-max(1000px,
    {
      .overlay-slider{ width: 55%;}
    }
    );
    .mq-max(800px,
    {
      .overlay-slider{ width: 50%;}
    }
    );

    & .content {
      padding: 0;
    }
  }

}

.column-left{
  .rgba(0, 0, 0, .95);
  .transition(background-color, 1s);
  .transition(width, 0.5s);
  position: relative;
  float: left;
  left:0;
  vertical-align: top;
  display: block;
  height: 100%;
  padding: 0;
  padding-bottom: 130px;
  z-index: 10000;
  box-shadow: 2px 1px 6px 0 rgba(0, 0, 0, 0.19);
  background-color: @font-ultralight-color;
  border-right: 3px solid @font-dark-color;
  overflow-x: hidden;
  &[data-toggle-state=hidden] {
    width: 6%;
    @media @mobile {
      width: 9%;
    }
    position: absolute;
    & ~ .mediaContainer {
      .transition(width, 0.5s);
      width:100%;
    }
    & .scroll-area-wrapper {
      .hide();
    }
    & #info-context-overlay {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  &[data-toggle-state=visible] {
    .col(3);
    @media @mobile {
      width: 75%;
    }
    padding: 0 15px;
    overflow: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: auto;
    & .scroll-area-wrapper {
      .show();
    }
    & #info-context-overlay {
      position: absolute;
      left: 15px;
      right: inherit;
    }
    & ~ .mediaContainer {
      width:75%;
      padding-left: 25px;
      & .context-title{
        left:3rem;
      }
    }
    & ~ .column[data-toggle-state=visible] ~ .mediaContainer {
      width: 50%;
    }

    & ~ .context-title{
      z-index: 1;
      & > div{
        top: 80px;
        bottom:inherit;
        left: 2.5rem;
      }
    }
  }
  & h1 {
    .font(24);
    font-weight: bold;
    border-bottom: 1px solid @brand-second;
    padding-bottom: 15px;
    margin-top: 0.5rem;
  }
}
& .context-title{
  color: #f1f1f1;
  z-index: 10000;
  left: 2rem;
  .col(3);
  padding-left:10px;
  position: absolute;
  min-height: 100%;
  & > div{
    position:absolute;
    bottom:120px;
    margin-left: -10px;

  }
  & h1{
    max-width: 80%;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 15px;
    line-height: 1.5rem;
    font-weight: normal;
    color: #ffffff;
    margin-top: 0.8rem;
  }
  & #info-context-overlay{
    display:inline-block;
    margin-right: 5px;
  }
}

.column{
  .col(3);

  .transition(background-color, 1s);
  .transition(width, 0.5s);
  float: right;
  right: 0;
  vertical-align: top;
  display: block;
  padding: 0;
  padding-bottom: 60px;
  height: 100%;
  z-index: 10000;
  border-left: 3px solid @brand-primary;
  box-shadow: -2px 1px 6px 0 rgba(0, 0, 0, 0.19);
  background-color: #fbfbfb;
  overflow-x: hidden;
  &[data-toggle-state=hidden] {
    width: 6%;
    position: absolute;
    min-width: 40px;
    & .title {
      .hide();
    }
    & .scroll-area-wrapper {
      .hide();
    }
    & button#paraclose{
      position: absolute;
      right: 0;
      left: 0;
      top: 15px;
      cursor: pointer;
      z-index: 10000000;
    }
    & button#info-overlay {
      position: absolute;
      right: 0;
      left: 0;
      top: 50px;
    }
  }
  &[data-toggle-state=visible] {
    width: 35%;
    margin-top: 0;
    right: 0;
    overflow: hidden;
    position: absolute;
    padding-left: 15px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: auto;
    box-shadow: -2px 1px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #fbfbfb;
    @media @tablet {
      width:45%;
    }
    @media @mobile {
      width: 98%;
    }
    & button#paraclose{
      position: absolute;
      right: 0;
      left: inherit;
      top: 15px;
      margin-right: 0;
    }
    & button#info-overlay {
      position: absolute;
      right: 0;
      left: inherit;
      top: 50px;
      margin-right: 0;    
    }
    & ~ .mediaContainer{
      .transition(width, 0.5s);
      width:65%;
      float: left;
      position: relative;
      @media @tablet {
        width:55%;
      }
      @media @mobile {
        width:5%;
      }
    }
  }
}

//////////////////////// GESTION TYPE DOC ////////////////////////////////

.type_document {
  width: 100%;
  height: 100%;
}

.frame-container-cell.type_document {
  vertical-align: top;
  height: 100%;

  &.no-visualisation {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .type_document {
      display: initial;
    }
  }

  .type_document {
    height: 90%;
    display: block;
    max-height: none;
    margin-top: 0;
  }
}

///////////////////////////TOOLBAR AND BUTTONS //////////////////////////////////
.viewer-controls{
  position: absolute;
  top: 0;
  height: 70px;
  display: block;
  left: 60px;
  color: #ffffff;
  z-index: 1000000;
  & .button-bar{
    display: inline-block;
    height: inherit;
  }
  #zoomIn{
    .icon-round-zoom_in();
  }
  #zoomOut{
    .icon-round-zoom_out();
  }
  & .pdf-pagination{
    & label {
      display: inline-block;
      margin: 0 @simple-padding;
      .font(13);
      color: @font-base-color;
      float: none;
    }
    & input[type="number"]{
      width: 60px;
      display: inline-block;
    }
    & i {
      .font(15);
      color: @font-base-color;
      margin: 10px;
    }
    & #totalPages{
      .font(13);
      color: @font-base-color;
      margin: 10px;
    }
    & #previous{
      .icon-round-expand_less();
      float: right;
    }
    & #next{
      .icon-round-expand_more();
      float: right;
    }
  }
}






.toolbar-wrapper {
  height: @toolbar-height;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  position: relative;
}
/*body.media {
  .bellow-img{
    display: none;
  }
}*/
.bellow-img{
  bottom: 30px;
  > .toolbar{
    position: absolute;
    left: 0;
    right: 0;
  }
}
[data-no-slider]{
  & .bellow-img {
    bottom: 35px;
  }
  & .media-slider-container {
    padding-bottom: 100px!important;
  }
}

.header-toolbar{
  display: none;
}

.toolbar, .viewer-controls {
  min-height: @toolbar-height;
  line-height: @toolbar-height;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-left: 15px;
  width: 100%;
  left: 0;
  z-index: 9999;
  @media @mobile, @tablet {
    min-height: @toolbar-height - 20;
    line-height: @toolbar-height - 20;
  }
  & button {
    margin: 0;
    padding: 0;
    display:inline-block;
    height: @toolbar-height;
    width: @toolbar-height;
    border: none;
    & p {display: none;}
    &:hover {
      &:before, &:after{
        color: @font-ultralight-color;
        background: @brand-primary;
      }
    }
    &:before, &:after{
      .font-size(26);
      color: @brand-primary;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
      background: @font-ultralight-color;
      padding: 5px;
      @media @mobile, @tablet {
        .font-size(26);
      }
    }
  }
}

// This wrapper is used to work around a bug in firefox where an element with
// a 100% width inside an element with `table-cell` display is sized according
// to his grand-parent instead of parent.

.no-visualisation {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  margin-top: -100px;

  h3 {
    font-weight: 300;
    .font(14);
  }
}


////////////////////////    PLAYER  VIDEO    //////////////////////////////////////////



.mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: none;
  background-color: @brand-primary !important;
}

.mejs-mediaelement {
  .me-cannotplay {
    width: 100% !important;
    height: 100% !important;

    // Doesn't seem to be useful (mdarse 07/03/2014)
    display: block;
    margin: 0 auto;
  }
}

/**
 * Overwrite FlexPaper
 */
.modal-content {
  display: block !important;
}



////// SIDEBAR ///////////


.retracted-content {
  .hide();
  width: 60px;
  float: left;
  position: absolute;
  right: 0;
  z-index: 900;
}

aside {
  overflow-y: hidden;
  -ms-overflow-style: hidden;

  .content{
    float: none;
    padding: @double-padding;
    height: 100%;
    display: block;
    padding-bottom: 40px;
    .scroll-area-wrapper {
      overflow-y: auto;
      position: relative;
      height: 100%;

      /* Component Needs */

      & .pc-tab > input,
      .pc-tab section > div {
        display: none;
      }

      & #tab1:checked ~ section .tab1,
      #tab2:checked ~ section .tab2,
      #tab3:checked ~ section .tab3 {
        display: block;
      }

      & .pc-tab {
        width: 100%;
        & nav {
          border-bottom: 1px solid @brand-second;
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        & ul {
          list-style: none;
          margin: 0;
          padding: 0;
          & li {
            display: inline-block;
            height: 47px;
            & label {
              .font-size(15);
              font-weight: bold;
              float: left;
              padding: 15px 40px;
            }
            &:not(:last-child) label {
              border-right-width: 0;
            }
          }
        }
        & section {
          clear: both;
          padding-bottom: 50px;
          &:before {
            content: '';
          }
          & div {
            width: 100%;
            line-height: 1.5em;
            letter-spacing: 0.3px;
          }
        }
      }

      & #tab1:checked ~ nav .tab1,
      #tab2:checked ~ nav .tab2,
      #tab3:checked ~ nav .tab3,
      #tab1:hover ~ nav .tab1,
      #tab2:hover ~ nav .tab2,
      #tab3:hover ~ nav .tab3{
        label {
          border-bottom: 4px inset @brand-second;
          position: relative;
          color: @brand-second;
          cursor: pointer;
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            left: 0;
            bottom: -1px;
          }
        }
      }

    }
    #info-overlay{
      position: absolute;
      top: 50px;
      right: 0;
      left: 0;
      margin: 0;
      z-index: 1;
    }
    & a {
      display: inline-block;
      color: @brand-second;
    }
  }
  .info {
    margin-top: 7px;
    &.retractable {
      display: none;
      position: absolute;
      top: 63px;
      right: 3px;
      cursor: pointer;
    }

    .glyphicon {
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }

  h4 {
    font-weight: 700;
    .font(14);
    color: @font-dark-color;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-family: Arial, sans-serif;
    .font(15) ;
    margin-bottom: 15px;
    margin-top: 5px;
    color: @font-dark-color;
    word-break: break-word;
    white-space: pre-line;
    & .Colorfield{
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid @font-ultralight-color;
      font-size: 0;
    }
    & span[data-copyable] {
      text-decoration:underline;
      background: rgba(0,0,0,0.2);
      border-bottom: dashed 1px rgba(0,0,0,0.8);
      cursor: help;
      font-style: normal;
      position: relative;
      display: inline-block;
      &::after {
        content: attr(data-info);
        display: inline;
        position: absolute;
        top: 15px; left: 0;
        opacity: 0;
        width: 230px;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.5em;
        padding: 0.5em 0.8em;
        background: rgba(0,0,0,0.8);
        color: #fff;
        pointer-events: none; /* This prevents the box from apearing when hovered. */
        transition: opacity 250ms, top 250ms;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 12px; left: 20px;
        opacity: 0;
        width: 0; height: 0;
        border: solid transparent 5px;
        border-bottom-color: rgba(0,0,0,0.8);
        transition: opacity 250ms, top 250ms;
      }
      &:hover {z-index: 2;} /* Keeps the info boxes on top of other elements */
      &:hover::after,
      &:hover::before {opacity: 1;}
      &:hover::after {top: 30px;}
      &:hover::before {top: 20px;}
    }
  }
}


// IMAGETTES SLIDER ///////

.overlay-slider{
  position: absolute;
  display: inline-block;
  bottom: 15px;
  left: 9%;
  height: 95px;
  width: 75%;
  max-width: 75%;
  overflow: hidden;
  z-index: 9999;
  float: left;

  &[data-toggle-state='visible'] {
    left: 29%;
    width: 55%;
    max-width: 55%;
  }
  & .centered {
    max-width:inherit
  }

  & .slider-wrapper{
    display: inline-block;
    width:30000%;
    padding: 5px;
  }

  & .active{
    .opacity(1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  }
}

.overlay-slide{
  margin-right: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  .opacity(0.4);
  &:hover{
    .opacity(1);
  }
  & .title{display: none;}
  & .image {
    position: relative;
    background-color: transparent;
    display:inline-block;
    & a{
      & .thumbnail-wrapper{
        width: auto;
        height:100%;
        display: table-cell;
        text-align: center;
        line-height: 80px;
        & img{
          max-width: 80px;
          max-height: 80px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          left: inherit;
          top: inherit;
          height: auto;
          width: inherit;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
        &  .background-thumbnail{
          display: none;
        }
      }
    }
  }

}

.overlay-slide-buttons {
  display: inline-block;
  position: absolute;
  bottom: 40px;
  right: 20px;
  height: 60px;
  z-index: 1;

  & button{
    margin: 0;
    padding: 0;
    display:inline-block;
    float: left;
    width: 40px;
    height: 60px;
    &:hover {
      color: @brand-third !important;
    }
    &:after{
      .font(60);
    }
  }
  & #paradeprev{
    .icon-baseline-chevron_left();
    @media @mobile, @tablet {

    }
  }
  & #paradenext{
    .icon-baseline-chevron_right();
    @media @mobile, @tablet {

    }
  }
  & .record-counter{
    display: inline-block;
    color: @font-dark-color;
    font-size: 2.2rem;
    padding-left: 1.2rem;
    & .current-record{
      display: block;
      color: @brand-primary;
      font-weight: bold;
      font-size: 4.2rem;
      line-height: 3rem;
      text-align: center;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid #808080;
      margin-bottom: 0.8rem;
    }
    & .length {
      &:before {
        content: 'de';
      }
    }
  }

}

.webui-popover {
  & .webui-popover-title {
    text-transform: uppercase;
    text-align: center;
  }
}


/////////// MEDIA QUERIES ///////

.mq-max(1450px,
{
  .overlay-slider{ width: 75%;}
}
);
.mq-max(1200px,
{
  .overlay-slider{ width: 65%;}
}
);
.mq-max(800px,
{
  .overlay-slider{ width: 50%;}
}
);


#info-context-overlay, #info-overlay {
  font-family: hollyglyph !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  text-decoration: none;
  font-size: 24px;
  font-size: 2.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:after {
    content: @holly-icon-outline-info;
  }
  &:hover, &:active{
    color: #9b8097;
    text-decoration: none;
  }
}

/////////// OVERLAY SPINNER ///////


.isloading-overlay{

  .bg-simple(@spinner)!important;

}

html {
  &[lang=fr] {
    & .record-counter{
      & .length {
        &:before {
          content: 'de ';
        }
      }
    }
  }
  &[lang=en] {
    & .record-counter{
      & .length {
        &:before {
          content: 'of ';
        }
      }
    }
  }
  &[lang=de] {
    & .record-counter{
      & .length {
        &:before {
          content: 'von ';
        }
      }
    }
  }
}


