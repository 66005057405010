@body-min-width: none;

body{
	.bg-contain(@body-default-bg, top, center,transparent);

	.color-panel {
		display: inline-block;
		position: absolute;
		top:1px;
		left: 1px;
		z-index: 200;
		@media @mobile {
			top: 0;
			left: 0;
		}
		& div {
			display: inline-block;
			width: 20px;
			height: 20px;
			border: 1px solid white;
			&.color1{
				background: @brand-primary;
			}
			&.color2{
				background: @brand-second;
			}
		}
	}
	& .container {
		position: relative;
		overflow-y: scroll;
		padding-right: 17px;
		box-sizing: content-box;
		width: 100%;
		height: 100%;
		margin: 0 auto;
        }

		& .filters-and-products {
			& .products {
				min-height: 365px;
			}
			.transition(padding-left, 0.5s);
			&[data-toggle-state=hidden]{
				// container toggle
				@media @xlarge, @large, @desktop {
					& .product-header {
						margin-right: 50px;
					}
					& .input-group {
						position: relative;
					}
				}
				@media @desktop {
					padding-left: 32%;
					& footer {
						left: 32%;
					}
				}
				@media @large {
					padding-left: 27%;
					& footer {
						left: 27%;
					}
				}
				@media @xlarge {
					padding-left: 22%;
					& footer {
						left: 22%;
					}
				}
			}
		}
	//Second opened, then first --> z index trick ...


	& .container-size {
            max-width: 1400px;
            margin: 0 auto;
        }
        & .global-container{
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0;
	}
}

html, body {
	margin: 0;
	padding: 0;
	border:none;
	font-family: @font-family-base;
	font-weight: 300;
	font-size: 62.5%;
	min-width: @body-min-width;
	position: relative;
	height: 100%;
	overflow: hidden;
}
.statik {
	& ul, li {
		list-style: inherit;
	}
}

hr {
	border: 0;
	border-top: 1px solid @font-light-color;
	width: 100%;
}

.intro {
	display: table;
	& .statik {
		width: 60%;
		display: table-cell;
		padding-right: 20px;
		@media @tablet, @mobile {
			display: block;
			width: 100%;
			padding-right: 0;
		}
		& ol, ul, li {
			list-style: initial;
			&:before{
				display: inline-block;
			}
		}
		& ol {
			& li {
				list-style-type: decimal;
			}
		}
		& p {
			.font(14);
		}
	}
	& .pageImage {
		width: 39%;
		display: table-cell;
		position: relative;
		vertical-align: middle;
		& img {
			width: 100%;
			height: auto;
		}

		@media @tablet, @mobile {
			display: block;
			width: 100%;
			padding-top: 0 !important;
		}
	}
}

.is-dragging {
	list-style: none;
	& .number{
		display: block;
		text-align: center;
		color: white;
		font-weight: bold;
	}
	& .selector-container {
		.hide();
	}
}

/* TO REMOVE */
.upc{
	& .container{
		& .content-header{
			& h1 {
				display: none;
			}
		}
		& h1{
			.font(32);
			margin-bottom: @double-padding;
			.col(8);
			float: right;
		}
		form{
			.col(8);
			float: right;
			margin-top: 0px;
			& .header{
				display: none;
			}
			& .reset{
				position: absolute;
				right: 15px;
			}
			& .filters-wrapper, .upc-file-wrapper {
				& #filters{
					& div{
						& textarea, #filters_codes{
							max-width: 100%!important;
							width: 100%!important;
							min-height: 400px;
						}
					}
				}
				& button{
					float: right!important;
					.btn();
					.col(4);
					font-size: 1.1rem!important;
				}
			}

			& .upc-file-wrapper {
				margin-top: 50px;
			}
		}
		& .buttons-wrapper-upc{
			.col(8);
			float: right;
			& .btn {
				float: right!important;
				margin-top: 0!important;
				font-size: 1.3rem!important;
				.col(4);
			}
		}
		.alert{
			bottom: 20rem;
			left: 9.7%;
		}
	}
	.upc-title {
		margin-top: 80px;
		float: left;
		width: 100%;
		padding: 0 30px;
		height: 40px;
		background-color: #4d4d4d;
		margin-left: 31px;

		& a {
			display: inline-block;
			color: #b3b3b3;
			font-weight: 700;
			.font-size(12);
			text-transform: uppercase;
			margin: 0;
			padding: 0;
			line-height: 40px;
			float: left;
		}
		& p {
			display: inline-block;
			font-weight: 700;
			.font-size(12);
			text-transform: uppercase;
			margin: 0;
			padding: 0;
			line-height: 40px;
			float: left;

		}
		& .title-separator {
			display: inline-block;
			margin: 0;
			padding: 0;
			color: #afafaf;
			.icon-right_thin();
			line-height: 40px;
			float: left;
			&:after {
				display: inline-block;
			}
		}
	}
	& button{
		&:hover{
			background-color: #3d3839 !important;
			border: solid 1px #302c2d !important;
		}
	}
}
/* END TO REMOVE */