
.icon-bouncy-customFunc {
  color: @brand-primary;
  &:after {
    position: relative;
    animation: bounce 0.7s ease infinite;
    @keyframes bounce{
      from {bottom: 2px;}
      50%  {bottom: -2px;}
      to   {bottom: 2px;}
    }
  }
}


aside.nav, aside.filters{
  width: 30%;
  left: -30%;
  @media @xlarge {
    width: 20%;
    left: -20%;
  }

  @media @large {
    width: 25%;
    left: -25%;
  }

  @media @tablet  {
    width: 40%;
    left: -40%;
    z-index: 1001 !important;
  }
  @media @mobile  {
    width: 80%;
    left: -80%;
    z-index: 1001 !important;
  }

  padding-bottom: @triple-padding;
  display: inline-block;
  position: fixed;
  top: @header-height + @list-controller-height;
  background: @brand-second;
  height: 100%;
  .transition(left, 0.5s);
  margin-top: 0;
  z-index: 1001;
  overflow: auto;
  &[data-toggle-state=hidden] {
    left: 0;
    //z-index: 1000;
  }
  & form {
    & .header{
      width: auto;
      vertical-align: top;
      height: 40px;
      line-height: 40px;
      cursor: initial;
      &:after {
        margin-right: 10px;
      }
    }
    & .reset{
      display: inline-block;
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding-right: 20px;
      color: @font-base-color;
      cursor: pointer;
      .font-size(14);
      &.collapse {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}
& aside.nav{
  padding-bottom: 170px;
}

& .mobile-menu {
  cursor: pointer;
  .icon-display-list();
  min-width: 40px;
  width: 10%;
  background-color: @brand-second;
  display: inline-block;
  height: @list-controller-height;
  line-height: @list-controller-height;
  z-index: 1004;
  padding: 0 10px;
  float: left;
  text-align: center;
  .font-size(12);
  @media @mobile, @tablet {
    font-size: 0;
    width: 7.5%;
  }
  &:after {
    .font-size(18);
    float: left;
    @media @mobile, @tablet {
      float: none;
    }
  }
  &:hover {
    color: @brand-primary;
  }
  & > span {
    font-family: @font-family-base;
    font-size: 12px;
    @media @mobile, @tablet {
      font-size: 0;
      width: 7.5%;
    }
  }
  &[data-toggle-state=hidden] {

  }
  &.filters-menu {
    .icon-round-settings_applications();
    font-size: 0;
    line-height: @list-controller-height;
    &:hover {
      color: @brand-primary;
    }
  }
  &.sticky-menu {
    &[data-toggle-state=hidden] {
    }
  }
  &.sticky-scrolltop {
    .icon-baseline-laptop_mac();
    right: 0;
    left: inherit;
    bottom: 150px;
    line-height: @list-controller-height;
    z-index: 1004;
    margin-top: 0;
    position: fixed;
    .icon-bouncy-customFunc();
    width: auto;
    padding: 0 20px;
  }
}

#filters{ 
  height: 100%;
  padding-top: 10px;
  padding-bottom: 120px;
  hr{display: none;}
}

#filters{
  & > .form-group{
    & label.required {
        .font(16);
        font-weight: 700;
    }
    & .search_type {
      & ul {
        margin-bottom: @simple-padding;
      }
    }
  }
}

/////////////// SEARCH TYPE ////////////////

.search_type{
  & input[type="radio"] + label{
    .font(12)!important;
    &:hover{
      color: @brand-primary !important;
      cursor: pointer;

    }
  }

  & input[type="radio"]:checked + label {
    color: @brand-primary !important;
    .font(12)!important;
  }
}

//////////////// FACETS  ///////////////////
.facets-container{
        overflow: hidden;
          border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none
}

.form-group {
  & [for="filters_query"] {
    color: @font-dark-color;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 10px;
    margin-top: 0;
  }
}


.facets {
  overflow: hidden;
  & h4{
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0 @simple-padding;
    padding-top: 10px;
    position: relative;
    border-top: 1px solid @font-darker-color;
    &:after {
      content: @holly-icon-baseline-chevron_right;
      .font-size(25);
      font-family: hollyglyph !important;
      position: absolute;
      right: 1.3rem;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    &:hover {
      color: @brand-primary;
    }
    &[data-toggle-state=hidden]{
      color: @brand-primary;
      &:after {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
      }
    }
  }
  & .right-inner {
    position: relative;
    margin-bottom: @simple-padding;
    padding: 0 20px;
    & input {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      background-color: #f5f5f5;
      margin: 0;
    }
    & i.calendar {
      right: 20px;
    }
  }
  & ul {
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    margin-bottom: 10px;
    .transition(max-height, 0.5s);
    &:hover{
      overflow-y: auto;
    }
    &[data-toggle-state=hidden] {
      max-height: 0;
    }
  }
  & ul > li {
    list-style: none;
    padding: 0 @simple-padding;
    & input[type="checkbox"]:checked + label {
      color: @brand-primary;
    }

    & input[type="checkbox"]{
      display: none;
    }
    & label {
      .font(13);
      font-weight: 300;
      color: @font-dark-color;
      text-transform: none;
      &:hover{
        color: @brand-primary;
        cursor: pointer;
      }
    }
  }
}
.facetscolor {
  & input[type="checkbox"]{
    display: none;
  }
  & h4{
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0 @simple-padding;
    padding-top: 10px;
    position: relative;
    border-top: 1px solid @font-darker-color;
    &:after {
      content: @holly-icon-baseline-chevron_right;
      .font-size(25);
      font-family: hollyglyph !important;
      position: absolute;
      right: 1rem;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    &.selected{
      color: @brand-second;
      &:after {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
      }
    }
    &:hover {
      color: @brand-second;
    }
  }
  & ul {
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    margin-bottom: @simple-padding;
    &:hover{
      overflow-y: auto;
    }
    & li {
      display: inline-block;
      width: 30px;
      float: left;
      margin: 2%;
      text-align: center;
      height: 30px;
      position: relative;

      & input[type="checkbox"]:checked + label {
        font-size: 0;
        border: 2px solid @brand-second;
        box-shadow: 1px 1px 12px @alert-color;
      }

      & label {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        line-height:3rem;
        color: #efefef;
        font-size: 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
        border: solid 1px #787878;
        background-color: #f5a623;

        &:hover{
          color: @brand-primary;
          cursor: pointer;
          text-decoration: underline;
          box-shadow: #333 0.2em 0.2em 0.18em;
        }
      }
    }
  }
}

//////////////// FILTERS ///////////////////

.form-group{
  & .checkbox{
    & input[type='radio']{
      display: none;
      &:checked + label {
        color: @brand-primary !important;
        .font(12)!important;
      }
      & + label[for="filters_search_type_0"], + label[for="filters_search_type_1"]{
        color: @brand-primary;
        border: 1px solid @brand-primary;
        border-radius: 15px;
      }
      &:checked + label[for="filters_search_type_0"], :checked + label[for="filters_search_type_1"], + label:hover {
        color: @font-ultralight-color !important;
        background: @brand-primary;
      }
    }
    & input[type='checkbox']{
      display: none;
      &:checked + label {
        color: @brand-primary !important;
        .font(12)!important;
      }
    }
    & label{
      .font(13);
      font-weight: 300;
      color: @font-base-color;
      text-transform: none;
      cursor: pointer;
    }
  }
  & .date-facets > label {
    display: none;
  }
}
