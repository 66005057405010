////////////////CGU FIX TEMPLATE 

body.CGU {
  & .container {
    & header{
      background-color: transparent;
      .row(12);
      & .logo-container{.container(250);}
      & .navbar {
        display: none;
      }
      & .form-search{display: none;}
    }
    & .content-header {
      display: none;
    }
  }
}


.cgu-modal{display: inline-block;}


////////////////CGU CONTAINER

.cgu-container{
  & .cgu-content{
    .col(12);
    padding-bottom: @double-padding;
    & article{
      .font-size(14);
      line-height: 20px;
      font-weight: 300;
      color: #373334;
      margin-top: @double-padding;
      white-space: pre-line;
    }
    & p {
      margin: 0;
    }
    & h1{
      .font(32);
    }
    & h2{
      .font(26);
    }
    & h3{
      .font(22);
    }
    & h4{
      .font(18);
    }
    & h5{
      .font(16);
    }
    & span, font, a, sup {
      display: inline-block;
    }
    & h1,h2,h3,h4,h5{
      margin: @simple-padding 0;
      text-transform: uppercase;
      font-weight: 700;
      color: #373334;
      max-width: inherit;
    }
    & a {
      text-decoration: underline;
    }
  }
}
////////////////ARTICLE

.CGU{
  & .container {
    & .content-header{display: none;}
    & header{
      .row(12);
      float: none;
      & .navbar{display: none;}
    }
    & .cgu-container{
      & .cgu-wrapper{
        max-height: 500px;
        overflow-y: scroll;
        // @media @large, @xlarge{
        // .row(12);

        // };
        .row(12);
        background-color: #e5e5e5;
        .border-radius();
        .drop-shadow();
      }
    }
  }
}

