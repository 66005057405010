
//// This must be remove from this styleshit

@header-height: 70px;
@header-mobile-height: 100px;
@header-right-height: @header-height;
@nav-height: @header-height;
@content-header-height: 115px;

@blade-element-color: @font-base-color;
////////////////////////////  ####### CONTENT HEADER - SECTIONS TITLES ########    ////////////////////////////

.content-header {
  /*.container(fluid,left, @content-header-height);
  line-height: @content-header-height;
  font-family: @font-family-base;
  //.bg-cover(@default-banner,center, 86%, @bg-default-color);
  .bg-cover(@default-banner,center, 86%, #b2b2b2);
    @media @mobile, @tablet{
    display: none;
  }*/
  h1 {
    margin:0;
    padding:0;
    .push(1);
    display: inline-block;
    vertical-align: middle;
    .font(48);
    font-weight: 100;
    color: @textcolor-overimg;
    @media @mobile {
      .font(25);
      width: 45%;
    }
  }
  & a {
    height: inherit;
    color: @textcolor-overimg;
  }
  & > h2 {
    .font(32);
    display: none;
    vertical-align: middle;
    color: @textcolor-overimg;
  }


  .media-counter {
    margin:0;
    padding:0;
    .pull(1);
    @media @mobile {
      .pull(0);
      .font(25);
      width: 45%;
      float: none;
      text-align: right;
      padding-right: 8%;
    }
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    float: right;
    text-transform: uppercase;
    color: @textcolor-overimg;
    text-align: left;

    .number {
      .font-size(40);
      display: inline-block;
      font-weight: 400;
    }
    .unit {
      .font-size(14);
      font-weight: 700;
      display: inline-block;

    }
  }
}




////////////////////////////  ####### HEADER ########    ////////////////////////////


.container-header {
  & header {
    .horizontal-nav();
  }
}


.vertical-nav{
  .col(12);
  margin-top: 40px;



    .header-bar-right {
      position: absolute;
      top: 0;
      right: @simple-padding;
      height: @header-right-height;


///// ACCOUNT + CONNECT //////

    .account/*.dropdown*/   {
      display: inline-block;
      float: right;
      height: @header-right-height;
      line-height: @header-right-height;
      margin-left: @double-padding;




        & a.dropdown-toggle {
          display: inline-block;
          vertical-align: middle;
          float: right;
          .font-size(12);
          color: #ffffff;
          line-height: @header-right-height;
          & .avatar{
            .container(40,40);
            display: inline-block;
            padding: 0;
            .icon-avatar();

            &:after{
              font-size: 40px;
              line-height: @header-right-height;
              display: inline-block;
              vertical-align: middle;
              float: right;
              color: @blade-element-color;
            }
          }
          & .caret {
            margin-left: 5px;
            display: inline-block;
            border-color: @blade-element-color;
          }
          & span{
            display: none;
          }
        }
    }
  }


///////////////////////// SEARCH BAR ///////////////
  & .form-search {
    .row(10);
    float: right;
    padding-left: 35px;

    & .input-group{
      position: relative;
      & input {
      height: 45px;
      box-shadow: none;
      width: 100%;
      border: none;
      max-width: 100% !important;
      .font(16);
      color: #373334;
      background-color: #b3b3b3;
    }

    button, button:hover, button:active {
      width: 45px;

      .icon-search();
      &:after{
        color: #373334;
        .font-size(20);
      }
      position: absolute;
      top: 2px;
      right: 10px;
      height: 50px;
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0
    }
  }
}

}

.horizontal-nav{
  top: 0;
  position: fixed;
  z-index: 1003;
  text-align: center;
  width: 100%;
  height: @header-height;
  display: block;
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.13);
  .bg-cover(@default-banner,center, center, #2b2b2b);
  & .fixed {
    position: relative;
    width: 100%;
    height: @header-height;
    line-height: @header-height;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0;
    border: 0;
    top: 0;
    & h2 {
      color: @font-ultralight-color;
      .font-size(32);
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      left: 190px;
      bottom: 0;
      margin: 0;
      max-width: inherit;
    }
    & .playimage {
      height: 50px;
      margin-top: 10px;
      position: absolute;
      right: 330px;
      @media @mobile {
        height: 20px;
        margin-top: 0px;
        right: 0px;
        top: 0px;
      }
    }
  }

//////////////////   ACCOUNT BLADE + CART ////////////////////
  .header-bar-right {
    width: 75%;
    position: absolute;
    top: 0;
    right: @simple-padding;
    text-align: right;

    .form-search {
      width: 360px;
      margin-top: 50px;
      margin-right: 20px;
      input {
        height: 50px;
        background-color: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: none;
      }
    }
  ///// ACCOUNT + CONNECT //////
    & .upload-btn {
      font-size: 16px;
      background: #ffffffaa;
      padding: 10px 15px;
      margin-left: 20px;
      border-radius: 8px;
      line-height: 70px;
      vertical-align: middle;
    }
    .account {
      display: inline-block;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      background: #ffffffbb;
      border-radius: 10px;
      text-align: center;
      height: 40px;
      width: 40px;
      line-height: @header-height;
      vertical-align: middle;
      &:hover{
        & a.dropdown-toggle {
          & .avatar{
            &:after{
              color: @brand-primary;
              }
            }
          }
      }

      & a.dropdown-toggle {
        display: inline-block;
        vertical-align: top;
        .font-size(12);
        color: @font-darker-color;
        height: 40px;
        line-height: 55px;

        & .avatar{
          .container(30,40);
          display: inline-block;
          .icon-avatar();
          padding: 0;

          &:after{
            font-size: 25px;
            display: inline-block;
            color: @font-darker-color;
          }
        }
      }
    }
  }

  .connect{
    position: fixed;
    left: @double-padding;
    display: inline-block;
    height: @header-right-height;
    line-height: @header-right-height;
    margin-left: @simple-padding;
    @media @mobile {
      left: inherit;
      right: 150px;
    }
    & .btn{
      margin: 0;
      background-color: @brand-primary;
    }
  }

  .account {
    &.open,
    &:hover {
      color: @brand-primary;
    }
    a.dropdown-toggle {
      display: inline-block;
      & > span {
        font-size: 0;
        display: inline-block;
      }
      .caret {
        margin-left: 5px;
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .dropdown-menu {
      right: 0;
      left: auto;

      // Prevent header override
      > li > a {
        color: @dropdown-link-color;
        &:hover,
        &:focus {
          color: @dropdown-link-hover-bg;
        }
      }
    }
    .picture {
      // .square(@header-height - ((@header-height - 34px)));
      padding: ((@header-height - 34px) / 2);
      vertical-align: top;
    }
  }
}


///////////////////ACCOUNT /////////////////////////

  .account .dropdown-menu {
    &:before {
      position: absolute;
      top: -10px;
      right: 19px;
      display: inline-block;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ccc;
      border-left: 10px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    &:after {
      position: absolute;
      top: -9px;
      right: 20px;
      display: inline-block;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #ffffff;
      border-left: 9px solid transparent;
      content: '';
    }
  }



  /////////////////////////////   LANG SWITCH ///////////////////////

.lang-switch {
  display: inline-block;
  position: relative;
  right: @simple-padding;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  height: 40px;
  vertical-align: middle;
  background: @font-ultralight-color;
  opacity: 0.8;
  border-radius: 10px;
  > li {
    display: inline-block;
    padding: 0 15px;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    vertical-align: top;
    border-right: 1px solid @font-darker-color;
    &:last-of-type {
      border-right: 0;
    }

    & a:hover {
      text-decoration: none;
      color: #b5b2b2;
    }
    &.active {
    }
    &.active a {
      color: @brand-primary;
      font-weight: bold;
    }
  }
  & a {
    .font(13);
    color: @header-font-color;
    display: inline-block;
    height: inherit;
    line-height: inherit;
    vertical-align: middle;
  }
}

.login {
   display: inline-block;
   height: @header-height;
   line-height: @header-height;
   @media @mobile {
     display: none;
   }
   > li {
     display: inline-block;
     padding: 0 @simple-padding;
     text-transform: uppercase;
     height: 40px;
     line-height: 40px;
     vertical-align: middle;
     &:first-child {
       border-right: 1px solid #b5b2b2;
     }

     & a:hover {
       text-decoration: none;
       color: #b5b2b2;
     }
     &.active {
     }
     &.active a {
       color: @brand-primary;
       font-weight: bold;
     }
   }
   & a {
     .font(13);
     color: @header-font-color;
     display: inline-block;
     height: inherit;
     line-height: inherit;
     vertical-align: middle;

   }
}

