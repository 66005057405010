 @popover-size: 200;
 @hover-font-color: #ffffff;  
 
 .reset{
    padding: 0;
    margin: 0;
    border: none;
    // .box-model(border-box);
 };

.webui-popover{
  min-width: ~'@{popover-size}px';
  max-width: 400px;
      padding: 0;
    margin: 0;
    border: none;
  .drop-shadow();
  .font(13);

  .webui-popover.right-bottom>.arrow, 
  .webui-popover.right-top>.arrow, 
  .webui-popover.right>.arrow,
  .arrow{
    border-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
  }

}

.webui-popover-content {
  padding: 0;
  border-radius: none;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;

    li {
      min-width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      &.last {
        border: none;
      }

      a {
        display: block;
        padding: @simple-padding;
        & span {
          display: block;
        }
        & b {
          display: block;
          .font(10);
        }
      }
      a:hover{
      background-color: @brand-primary;
      text-decoration: none;
      color: @hover-font-color;  
      }
    }
  }
}