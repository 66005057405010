
@auth-text-color: #ffffff;
html[lang=en] {
  body.auth {
    .login-logo {
      & a {
        .bg-contain(@black-logo-en-png);
      }
    }
  }
}

body.auth {
 margin: 0;
 height: 100%;
 min-height: 100%;
  width: 100%;
  .bg-fixed(@body-error-bg, top, center,@bg-default-color);
  & .auth-container {
    display: block;
    width: 100%;
    height:100%;
    text-align: center;
    background-color: transparent;
    padding: @triple-padding 0;
    overflow: auto;
    margin-right: -17px;
    & .auth-centered{
      width: 450px;
      position: relative;
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      background: @brand-primary-alpha;
      padding: @simple-padding @triple-padding;
      @media @mobile{
        .col(10);
        float: none;
        display: inline-block;
        vertical-align: middle;
      };
      & .icon-baseline-chevron_right {
        position: absolute;
        bottom: 10px;
        right: 0;
        cursor: pointer;
        color: rgba(233, 4, 30, 0.7);
        &[data-toggle-state=visible] {
          transform: rotate(90deg);
          & ~ .hiddenform {
            height: 250px;
            .transition(height,1s);
          }
        }
      }
      & .hiddenform {
        height: 0;
        overflow: hidden;
      }
      & .container-fluid {
        & form {
          & label {
            color: #ffffff;
          }
        }
        & #submit {
          width: 200px;
          height: 30px;
          line-height: 30px;
          background: #ffffff;
          color: @brand-primary;
          &:hover{
            background: @brand-primary;
            color: #ffffff;
            border: 1px solid #fff;
          }
        }
      }

    }

  }
  & .form-login {
    overflow: hidden;
    .transition(height, 400ms);
    &[data-toggle-state=visible] {
      height: 270px;
    }
    & input {
      margin: 0 auto;
    }
    & .ui-checkbox {
      position: relative;
      right: 130px;
      margin-bottom: 10px;
    }
  }

  .login-logo {
    margin-bottom: @double-padding;
    & a{
      display: block;
      .bg-contain(@home-logo-png);
      height: 120px;
      margin: 0 auto;
      float: none;
    }
    p {
      display: none;
      color: @auth-text-color;
      .font(14);
      margin-bottom: 20px;
    }

  }
  & .playimage {
    height: 50px;
    margin-top: 10px;
    position: absolute;
    right: 130px;
  }
////////////////////////// LANG SWITCH //////////////////////////////
  .lang-switch{
      position: absolute;
      display: inline-block;
      top: 10px;
      right: 10px;
      .font-size(14);
      float: right;
      background: @font-ultralight-color;
      opacity: 0.7;
      z-index: 1001;
    & li{
      list-style: none;
      display: block;
      float: left;
      margin: 0;
      text-transform: uppercase;
      a{
        color:@font-dark-color;
      }

      a:hover{

      color: @brand-second;
      text-decoration: none;
      font-weight: bold;
      }
    }

    .active{

      & a {
        font-weight: bold;
      }
    }
  }
////////////////////////// LANG SWITCH //////////////////////////////

  h2, h4 {
    text-align: center;
    text-transform: uppercase;
    color: @font-ultralight-color;
    .font(19);
    margin: 0;
    font-weight: 700;
  }

  .form-group {
    label {
      color: @font-dark-color;
     .font(14);
     text-transform: none;
     font-weight: normal;
    }

  }
// Pass recover link in login template
  .pass-recover {
    height: auto;
    margin: 15px 0 0 0;
    clear: both;
    position: relative;
    text-align: left;
    top: -110px;
    display: inline-block;
    float: left;

    a {
      color: @font-ultralight-color;
      font-size: 14px;
      text-decoration: underline;
      font-weight: bold;
      display: block;
    }

    a:hover{color: @brand-primary;}

  }

  & #user_registration {
    & label {
      color: @font-ultralight-color;
    }
  }
// Registration link in login template
  .oauth-text{

  }



  /////////////////////// SIGIN UP /////////////////

  footer{
    background-color: transparent;
    margin-bottom:0;
    margin-top: 0;

   .contact {
      text-align: left;
      font-size: 12px;
      display: block;
      margin: 13px auto auto auto;
      color: #737373;
      text-decoration: none;
    }
  }
}



/////////////////////////// SIGN UP LINK


.request-access{
  text-align: center;
  .font(0);
  color: #ffffff;
  margin-bottom: 1rem;
  padding: 0 @simple-padding;
  .btn {
    width: 85%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  }
}

  .help-block{
     text-align: left;
     .font(13);
     text-transform: none;
     font-weight: 300;
  }
