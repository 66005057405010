body.basket {
  @media @mobile {
    & .filters-and-products {
      padding-top: @header-height + @list-controller-height + @simple-padding;
    }
  }
  & .container {
    & header{
      & .form-search{display: none;}
    }
    & .content-header {
      margin-top: 0;
    }
    & .main-nav.navbar {
      @media @tablet, @mobile{
        border-bottom: 0;
      };
      & .cart {
        background: @brand-second;
        @media @tablet, @mobile{
          background: @brand-second-alpha;
        };
        & span {
          color: @font-ultralight-color !important;
        }
      }
    }
  }
  & .actions-and-products {
    & h2{
      display: block;
      color: @font-dark-color;
      .font-size(26);
      font-weight: bold;
      margin: 0;
      padding:0;
      line-height: 40px;
    }
  }
  & .cart-actif {
    display: inline-block;
    border: 1px solid @brand-primary;
    float: right;
    padding: 9px 30px;
    position: absolute;
    right: 20px;
    top: @header-height + @list-controller-height + 30;
    .font-size(14);
    font-weight: bold;
    color: @brand-primary;
  }

  & aside .actions {
    .intro{display:none;}
    .col(12);
    padding-top: @simple-padding;
    text-align: center;
    & .btn {
      max-width: 300px;
      font-size: 14px;
      text-align: left;
      color: @font-dark-color;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background-color: #f5f5f5;
      margin: 5px 0;
      height: 50px;
      padding: 5px 25px 14px;
      font-weight: bold;
      line-height: 40px;
      border: 0;
      .transition(background-color, 0.5s );

      & span {
        float: right;
        line-height: 40px;
        &:hover {
          color: inherit;
        }

      }

      &:hover {
        color: #666;
        background-color: @brand-primary;
        border-color: #c3c3c3;
      }
    }
  }


  // Column layout
  .actions-and-products {
    padding-bottom: 20px;
    display: block;
    width: 100%;
    & .empty-cart {
      margin-top: 5rem;
      color: @font-darker-color;
      .font(16);
    }
    & h1{
      .font(26);
      color: @font-darker-color;
      margin: 0;
      padding-right: @double-padding;
      border-bottom: @border-light;
      clear: both;
      padding-bottom: @simple-padding;
    }
  }
  & .cart-counter{
    display: inline-block;
    color: @brand-primary;
    .font-size(18);
    font-weight: bold;
    margin: 0;
    padding:0;
    line-height: 43px;
    margin-right: 0.3rem;
  }
  & .unit{
    display: inline-block;
    color: @brand-primary;
    font-weight: bold;
    .font-size(11);
    margin: 0;
    padding:0;
    line-height: 43px;
  }
}



.cart-header{
  height: 85px;
  line-height: 85px;
  .row(12);
  float: left;
  & .list-switch{
    display: inline-block;
    line-height: 85px;
    vertical-align: middle;
  }
}


.bar-buttons{
  display: inline-block;
  vertical-align: middle;
  & form{
    display: inline-block;
    float: left;
  }

}

.cart-breadcrumb{
  .row(12);
  // margin-left: @double-padding;
  padding: 0;
  height: 40px;
  margin-bottom: 0;

  & a{
    display: inline-block;
    color: @font-light-color;
    .font-size(10);
    font-weight: 500;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;
    &:hover {
      color: @brand-primary;
    }
  }
  & p{
    display: inline-block;
    color: @font-light-color;
    .font-size(12);
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;

  }
  & .breadcrumb-separator{
    display: inline-block;
    color: @font-light-color;
    margin: 0;
    padding:0;
    .icon-baseline-chevron_right();
    line-height: 40px;
    float: left;
    &:hover {
      color: @font-light-color;
    }
    &:after{
      display: inline-block;
    }
  }
  & .cart-counter{
    display: inline-block;
    color: @font-darker-color;
    .font-size(17);
    margin: 0;
    padding:0;
    line-height: 43px;
    float: right;
    margin-right: 0.3rem;
  }
  & .unit{
    display: inline-block;
    color: @font-darker-color;
    .font-size(17);
    margin: 0;
    padding:0;
    line-height: 43px;
    float: right;
  }
}




































/*

.product-block-list li:hover {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.product-block-list li:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.is-active {
  z-index: 1005 !important;
}

.is-dropped {
  opacity: 0;
  -webkit-transform: scale3d(0.7,0.7,1) !important;
  transform: scale3d(0.7,0.7,1) !important;
}

.is-complete {
  opacity: 1;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s !important;
  transition: opacity 0.3s, transform 0.3s !important;
  -webkit-transform: scale3d(1,1,1) !important;
  transform: scale3d(1,1,1) !important;
}

.animate {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.drop-area {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 1001;
  width: 40rem;
  height: 100%;
  overflow-y: visible;
  background: @brand-primary;
  text-align: left;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  opacity: 0.98;
  padding: 0 50px;
  padding-top: 4rem;
  & .glyphicon {
    left: -90px;
    top: 100px;
  }
  & .cart-items {
    position: relative;
    overflow: hidden;
    & ul {
      padding: 0;
      overflow-y: scroll;
      max-height: 570px;
      position: relative;
      right: -17px;
      padding-top: 5px;
      & li {
        @media @xlarge{.items(@items-desktop);}
        @media @large{.items(@items-desktop);}
        @media @desktop{.items(@items-desktop);}
        @media @tablet{.items(@items-desktop);}
        @media @mobile{.items(@items-desktop);}
        height: 100px;
        margin-bottom: 1.5rem;
        & .item {
          height: 100px;
        }
        & .image {
          max-height: 100px;
          height: 100px;
          line-height: 100px;
        }
        &:after {
          content:'';
          clear: both;
        }

        & .selector-container {
          .hide();
        }
        & .draggable {
          .hide();
        }
        & .caption {
          .hide();
        }
      }
    }
  }
  & .actions{
    a {
      display: block;
      position: absolute;
      width: 100%;
      border: 2px solid white;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 1.5rem;
      color: @font-ultralight-color;
      font-weight: bold;
      &.remove {
        bottom: 60px;
      }
      &.download {
        bottom: 130px;
      }
    }
  }
}

.drop-area.show, .drop-area[data-toggle-state=visible] {
  -webkit-transform: translate3d(-40rem,0,0);
  transform: translate3d(-40rem,0,0);
}

.drop-area > .drop-container {
  position: relative;
  height: 100%;
  -webkit-transition: -webkit-transform 0.4s 0.1s;
  transition: transform 0.4s 0.1s;
  -webkit-transform: translate3d(40rem,0,0);
  transform: translate3d(40rem,0,0);
  & h2 {
    text-align: right;
    padding: 0 4rem 2rem 0;
    text-transform: none;
    font-size: 2.5rem;
  }
  .cross{
    width: 20px;
    height: 20px;
    border-radius: 50% 50%;
    position: absolute;
    transform: scale(1.3);
    top: 0.6rem;
    &::before{
      background: #f1f1f1;
      position: absolute;
      display:block;
      content:'';
      width: 70%;
      height: 6%;
      top: 47%;
      left: 15%;
      transform: rotate(45deg);
    }
    &::after{
      background: #f1f1f1;
      position: absolute;
      display:block;
      content:'';
      width: 6%;
      height: 70%;
      left: 47%;
      top: 15%;
      transform: rotate(45deg);
    }
    &:hover {
      cursor: pointer;
      &::before{
        background: #777;
      }
      &::after{
        background: #777;
      }
    }
  }
}

.drop-area.show > .drop-container, .drop-area[data-toggle-state=visible] > .drop-container{
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  & .cart-counter {
    float: right;
    font-size: 2.2rem;
    position: absolute;
    right: 0;
    top: 0.2rem;
    color: #fff;
  }
}

.drag-here {
  display: none;
}

.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1001;
  float: left;
}

.drop-area.show + .drop-overlay {
  opacity: 1;
}

.helper {
  font-size: 2em;
  position: absolute !important;
  margin: 0;
  width: 100px;
  &:before {
    content:'';
    position: absolute;
    right: 10px;
    top: 10px;
    background-image:url('../img/drag.png');
    width:20px;
    height:20px;
    background-size: contain;
    border-radius: 15px;
    z-index: 1;
  }
}

@media screen and (max-width: 50em) {
  .grid__item {
    font-size: 1.5em;
  }
  .drop-area {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 25.5em) {
  .grid {
    padding: 0 2em;
  }
  .grid__item {
    font-size: 1em;
  }
}

.drop-area.show {
  & .cart-items {
    display: none;
  }

  & .drag-here {
    position: absolute;
    top: 150px;
    display:block;
    background: @brand-primary;
    border:1px dashed #fff;
    text-align: center;
    width:75%;
    padding: 25px 0;
    margin: 0 auto;
    min-height: 500px;
    line-height: 500px;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    &.highlight {
      background: rgba(50, 100, 135, 0.9);
      -webkit-transform: scale3d(1.05,1.05,1);
      transform: scale3d(1.05,1.05,1);
    }
  }
  & .actions {
    display: none;
  }
}
*/
